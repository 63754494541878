import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list = _resolveComponent("list")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_editScript = _resolveComponent("editScript")!
  const _component_grammarSpecification = _resolveComponent("grammarSpecification")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createBlock(_component_a_tabs, {
    activeKey: _ctx.activeKey,
    "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tab_pane, {
        key: "1",
        tab: "脚本列表"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_list)
        ]),
        _: 1
      }),
      _createVNode(_component_a_tab_pane, {
        key: "2",
        tab: "编辑"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_editScript)
        ]),
        _: 1
      }),
      _createVNode(_component_a_tab_pane, {
        key: "3",
        tab: "语法规范"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_grammarSpecification)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["activeKey"]))
}