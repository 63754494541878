
import {defineComponent, ref} from "vue";
import list from "./modules/list.vue"
import editScript from "./modules/editScript.vue"
import grammarSpecification from "./modules/grammarSpecification.vue"

export default defineComponent({
  components: {list, editScript, grammarSpecification},
  setup() {
    return {
      activeKey: ref<string>("1"),
    }
  }
})
