
import {defineComponent, getCurrentInstance, onMounted, ref, watch} from 'vue';

export default defineComponent({
  name: "MonacoEditor",
  props: {
    //只读
    codes: {
      type: String,
      default: ""
    },
    //只读
    readOnly: {
      type: Boolean,
      default: false
    },
    //自动布局
    automaticLayout: {
      type: Boolean,
      default: false
    },
  },
  setup(props: any,context) {
    const myEditor = ref();
    //主要配置
    let editorOptions: any = {
      // selectOnLineNumbers: true,
      // roundedSelection: false,
      readOnly: props.readOnly, // 只读
      // cursorStyle: 'line', // 光标样式
      automaticLayout: props.automaticLayout, // 自动布局
      // glyphMargin: true, // 字形边缘
      // useTabStops: false,
      // fontSize: 28, // 字体大小
      // autoIndent: false // 自动布局
    };
    let monacoEditor: any;
    const monaco = getCurrentInstance()?.appContext.config.globalProperties.$monacoEditor

    const initEditor = () => {
      // let a : any = document.getElementById("editor-section");
      // let b : any = myEditor.value;
      monacoEditor = monaco.editor.create(myEditor.value, {
        value: props.codes, // 见props
        language: 'javascript',
        theme: 'vs-dark', // 编辑器主题：vs, hc-black, or vs-dark，更多选择详见官网
        ...editorOptions
      })

      //键入时更新v-model:codes
      monacoEditor.onDidChangeModelContent(() => {
        const newValue=monacoEditor.getValue();
        context.emit("update:codes",newValue)
      })
    }
    watch(props, () => {
      //外部传值不是键入时，更新codes
      if (props.codes != monacoEditor.getValue()) {
        monacoEditor.setValue(props.codes)
      }
    });

    onMounted(() => {
      initEditor()
    })

    return {
      myEditor,
      monacoEditor,
    }
  }
})
