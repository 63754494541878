
import {defineComponent, ref, onMounted,UnwrapRef,reactive,createVNode,nextTick} from "vue";

import {getScriptList} from "@/api/api_x2server";
import type { TableProps } from 'ant-design-vue';
const columns = [
  {
    title: '脚本实例ID',
    dataIndex: 'HashCode',
  },
  {
    title: '开始时间',
    dataIndex: 'StartTime',
  },
  {
    dataIndex: 'Duration',
    title: '已执行时间'
  },
  {
    dataIndex: 'Info',
    title: '脚本信息'
  }
]


export default defineComponent({
  setup() {
    const loading = ref<boolean>(false)
    const dataSource = ref<any>([])
    const pagination = ref<any>({
      total: 0,
      current: 1,
      pageSize: 10,
    })
    onMounted(()=>{
      getList()
    })
    const handleTableChange: TableProps['onChange'] = (
        pag: any,
    ) => {
      pagination.value = pag
      getList()
    };
    const getList = async () => {
      const res: any = await getScriptList({
        page:pagination.value?.current,
        count:pagination.value?.pageSize
      })
      dataSource.value = res.Items;
      pagination.value!.total = res.TotalCount
    }
    return{
      loading,
      columns,
      dataSource,
      pagination,
      handleTableChange,
    }
  }
})
