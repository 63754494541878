import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_table, {
      style: {"margin-top":"10px"},
      bordered: "",
      rowKey: "Id",
      columns: _ctx.columns,
      "data-source": _ctx.dataSource,
      pagination: _ctx.pagination,
      loading: _ctx.loading,
      onChange: _ctx.handleTableChange
    }, null, 8, ["columns", "data-source", "pagination", "loading", "onChange"])
  ]))
}