
import {defineComponent, ref, onMounted} from 'vue';
import MonacoEditor from "@/components/MonacoEditor/MonacoEditor.vue";
import {callScript, runScript} from "@/api/api_x2server";
import {message} from 'ant-design-vue';
export default defineComponent({
  components:{MonacoEditor},
  setup(){
    const callTextName = ref<string>("")
    const callTextParam = ref<string>("")
    const codes = ref<string>("")

    const runJs = async () => {
      const res: any = await runScript({ data:codes.value})

      if (res.Result.toUpperCase() == "OK") {
        message.success("运行成功！")
      }
    }
    const callJs = async () => {
      if(callTextName.value == ""){
        message.error("请输入函数名！")
        return
      }
      let param = callTextParam.value;
      let data: any = {
        Name:callTextName.value
      };
      let params = param.split("&");
      for(let i =0;i<params.length;i++){
        let par = params[i].split("=");
        if(par.length !=2){
          message.error("参数格式错误!")
          return;
        }
        data[par[0]] = par[1];
      }
      const res: any = await callScript(data)

      if (res.Result.toUpperCase() == "OK") {
        message.success("运行成功！")
      }
    }
    return{
      callTextName,
      callTextParam,
      codes,
      runJs,
      callJs,
    }
  }
})
